import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@app/services/auth.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiURL: string = environment.apiUrl;
  private accessToken: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.authService.accessToken$.subscribe(accessToken => {
      this.accessToken = accessToken;
    });
  }

  get<T>(url: string): Observable<T> {
    console.log('running get xhr', this.accessToken, url);
    return this.http.get<T>(`${this.apiURL}/${url}`, {
      headers: {
        'Authorization': `Bearer ${this.accessToken}`
      }
    });
  }

  put<T>(url: string, body: any): Observable<T> {
    return this.http.put<T>(`${this.apiURL}/${url}`, body,
      {
        headers: {
          Authorization: `Bearer ${this.accessToken}`
        }
      });
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(`${this.apiURL}/${url}`, body,
      {
        headers: {
          Authorization: `Bearer ${this.accessToken}`
        }
      });
  }
}
