import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AuthService } from './services/auth.service';
import { HomeComponent } from './components/pages/home/home.component';
import { MaterialModule } from './modules/material-module/material.module';
import { CallbackComponent } from './components/pages/callback/callback.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { ApiService } from './services/api.service';
import { ClientService } from './modules/client/client.service';
import { HttpClientModule } from '@angular/common/http';
import {ClientModule} from './modules/client/client.module';
import { AgGridModule } from 'ag-grid-angular';
import { GlyphModule } from './modules/glyph/glyph.module';
import { SharedModule } from './modules/shared/shared.module';
import { ClarityModule } from '@clr/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LogoutComponent } from './components/logout/logout.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CallbackComponent,
    HeaderComponent,
    FooterComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    AgGridModule.withComponents([]),
    GlyphModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    BrowserAnimationsModule
  ],
  providers: [
    AuthService,
    ApiService,
    ClientService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
