import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})
export class GlyphModule {
  private _glyphPath = 'assets/glyphs';

  constructor(
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry) {
    //add custom material icons
    matIconRegistry.addSvgIcon('reload', domSanitizer.bypassSecurityTrustResourceUrl(`${this._glyphPath}/glyphicons-basic-86-reload.svg`));

  }
}
