import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'akpc-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {
  @Input() tableData: any[] = [];
  @Input() columnDefs: any[];
  // @Output() rowClickedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowClickedEvent: EventEmitter<any> = new EventEmitter<any>();
  gridOptions;

  constructor() { }

  ngOnInit() {
    this.gridOptions = {
      enableSorting: true,
      enableFilter: true
    };
  }

  rowClicked($event) {
    this.rowClickedEvent.emit($event);
  }
}
