import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.authService.isAuthenticated()
      .subscribe(isAuthenticated => {
        console.log(`[HomeComponent] authenticated`, isAuthenticated);
        if (!isAuthenticated) {
          this.authService.login();
        }
      });
  }

}
