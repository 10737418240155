import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './components/pages/home/home.component';
import {CallbackComponent} from './components/pages/callback/callback.component';
import { LogoutComponent } from '@app/components/logout/logout.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: {
      title: 'Home'
    }
  },
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'clients',
    loadChildren: './modules/client/client.module#ClientModule'
  },
  {
    path: 'devices',
    loadChildren: './modules/device/device.module#DeviceModule'
  },
  {
    path: 'products',
    loadChildren: './modules/product/product.module#ProductModule'
  },
  {
    path: 'tickets',
    loadChildren: './modules/ticket/ticket.module#TicketModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
