import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorConstants } from '@app/constants/validator.constants';
import { StoreService } from '@app/services/store.service';
import { AuthService } from '@app/services/auth.service';
import { NoteModel } from '@modules/shared/models/note.model';

@Component({
  selector: 'akpc-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {
  @Input() noteTypes: string[] = [];
  @Input() noteType: string;
  @Input() noteText: string;
  @Input() isRequired: boolean = false;
  @Input() readonly: boolean = false;
  @Input() record: any;
  @Output() saved: EventEmitter<NoteModel> = new EventEmitter<NoteModel>();
  noteForm: FormGroup;
  maxLength: number = ValidatorConstants.NOTES_LENGTH;
  originalText: string;

  constructor(private fb: FormBuilder,
              private storeService: StoreService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.originalText = this.noteText;
    const modifiedAt = new Date().toISOString();
    const modifiedBy = this.authService.userID$.getValue();
    let createdAt = modifiedAt;
    let createdBy = modifiedBy;
    let isDeleted = false;
    const subscriberID = this.storeService.getValue('subscriberID');
    const locationID = this.storeService.getValue('locationID');
    if(this.record) {
      createdAt = this.record.createdAt;
      createdBy = this.record.createdBy;
      isDeleted = this.record.isDeleted;
    }
    this.noteForm = this.fb.group({
      noteType: new FormControl(this.noteType || ''),
      noteText: new FormControl(this.noteText || '', [Validators.maxLength(this.maxLength), Validators.required]),
      createdAt: new FormControl(createdAt),
      createdBy: new FormControl(createdBy),
      modifiedAt: new FormControl(modifiedAt),
      modifiedBy: new FormControl(modifiedBy),
      isDeleted: new FormControl(isDeleted),
      subscriberID: new FormControl(subscriberID),
      locationID: new FormControl(locationID)
    });
  }

  cancel(): void {
    this.noteForm.controls.noteText.setValue(this.originalText);
    this.noteForm.markAsPristine();
  }

  save(): void {
    this.saved.emit(this.getFormData());
    this.originalText = this.noteForm.controls.noteText.value;
    this.noteForm.markAsPristine();
  }

  getFormData(): NoteModel {
    return {
      noteType: this.noteForm.controls.noteType.value,
      noteText: this.noteForm.controls.noteText.value,
      createdAt: this.noteForm.controls.createdAt.value,
      createdBy: this.noteForm.controls.createdBy.value,
      modifiedAt: this.noteForm.controls.modifiedAt.value,
      modifiedBy: this.noteForm.controls.modifiedBy.value,
      isDeleted: this.noteForm.controls.isDeleted.value,
      subscriberID: this.noteForm.controls.subscriberID.value,
      locationID: this.noteForm.controls.locationID.value,
    };
  }
}
