import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlyphModule } from '../glyph/glyph.module';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MaterialModule } from '../material-module/material.module';
import { GridComponent } from './components/grid/grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { ProductNamePipe } from '@app/modules/shared/pipes/product-name.pipe';
import { NoteComponent } from './components/note/note.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    GlyphModule,
    MaterialModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([])
  ],
  exports: [
    SpinnerComponent,
    GridComponent,
    ProductNamePipe,
    NoteComponent
  ],
  declarations: [
    SpinnerComponent,
    GridComponent,
    ProductNamePipe,
    NoteComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule { }
