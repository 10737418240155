import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { environment } from '@env/environment';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { map } from 'rxjs/operators';

(window as any).global = window;

@Injectable()
export class AuthService implements OnInit {

  auth0 = new auth0.WebAuth({
    clientID: environment.auth.clientID,
    domain: environment.auth.domain,
    responseType: 'token id_token',
    redirectUri: environment.auth.callbackURL
  });

  // TODO: straighten this up - on login, it passes the accessToken (per the callback's property), but we really want the idToken to be passed to the API for authentication.
  accessToken$: BehaviorSubject<string> = new BehaviorSubject(null);
  idToken$: BehaviorSubject<string> = new BehaviorSubject(null);
  userID$: BehaviorSubject<number> = new BehaviorSubject(null);

  constructor(public router: Router,
              protected localStorage: LocalStorage) {
    console.log(`[AuthService::constructor]`);
    this.localStorage.getItem('id_token').subscribe(token => {
      this.accessToken$.next(token);
      console.log('[AuthService::constructor] token', token);
    });
    // TODO: wire up with live data
    this.userID$.next(1);
  }

  ngOnInit(): void {
    console.log(`[AuthService::ngOnInit]`);

  }

  public login(): void {
    this.auth0.authorize();
  }

  public handleAuthentication(): void {
    // TODO: Need to retrieve userID, subscriberID, locationID from Auth0 and set those in authService and storeService
    // TODO: Have it retrieve stuff on refresh from Auth0, rather than localStorage?
    this.auth0.parseHash((err, authResult) => {
      console.log('[AuthService::handleAuthentication]', err, authResult);
      if (err) {
        this.router.navigate(['']);
        console.log(err);
        return alert(`Error: ${err.error}. Check the console for further details.`);
      }
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult)
          .subscribe(res => {
            console.log('set session');
            this.router.navigate(['']);
          });
      }
    });
  }

  private setSession(authResult) {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    return forkJoin(
      [
        this.localStorage.setItemSubscribe('access_token', authResult.accessToken),
        this.localStorage.setItemSubscribe('id_token', authResult.idToken),
        this.localStorage.setItemSubscribe('expires_at', expiresAt),
      ]).pipe(res => {
      console.log('setSession', this.localStorage, res);
      this.accessToken$.next(authResult.accessToken);
      // TODO: wire up with live data
      this.userID$.next(1);
      return of(true);
    });
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    this.localStorage.removeItemSubscribe('access_token');
    this.localStorage.removeItemSubscribe('id_token');
    this.localStorage.removeItemSubscribe('expires_at');
    this.accessToken$.next(null);
    this.userID$.next(null);
    console.log('logging out');
    // Go back to the home route
    this.router.navigate(['/']);
  }

  public isAuthenticated() {
    let expiresAt: number = 0;
    let accessToken: string = '';
    // this.localStorage.getItem('expires_at').subscribe(expiry => {
    //   console.log('expiry', expiry);
    // })

    // Check whether the current time is past the
    // access token's expiry time
    return forkJoin(
      [
        this.localStorage.getItem<number>('expires_at')/*.subscribe(res => {
          expiresAt = res;
          return res;
        })*/,
        this.localStorage.getItem<string>('access_token')/*.subscribe(res => {
          accessToken = res;
          return res;
        })*/,
      ]
    )
      .pipe(map(([expiry, token]) => {
        expiresAt = expiry;
        accessToken = token;
        const authenticated = (!!accessToken && new Date().getTime() < expiresAt);
        console.log(`authenticated check authenticated ${authenticated}, expiry ${expiresAt}, accessToken ${accessToken}`);
        return authenticated;
      }));
  }

}
