import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'akpc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  companyName: string;
  menuItems: IMenuItem[];

  constructor() {
  }

  ngOnInit() {
    this.companyName = 'Astute Point';

    this.menuItems = [
      {
        name: 'Home',
        path: 'home'
      },
      {
        name: 'Clients',
        path: '/clients'
      },
      {
        name: 'Devices',
        path: 'devices'
      },
      {
        name: 'Products',
        path: 'products'
      },
      {
        name: 'Tickets',
        path: 'tickets'
      }
    ];


  }
}

export class IMenuItem {
  name: string;
  path: string;
}
