import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  subscriberID$: BehaviorSubject<number> = new BehaviorSubject(null);
  locationID$: BehaviorSubject<number> = new BehaviorSubject(null);

  constructor() {
    // TODO: wire up (in ngOnInit) with live data
    this.subscriberID$.next(1);
    this.locationID$.next(1);
  }

  setValue(key: string, value: any) {
    switch (key) {
      case 'subscriberID':
        this.subscriberID$.next(value);
        break;
      case 'locationID':
        this.locationID$.next(value);
        break;
    }
  }

  public getValue(key: string) {
    let value;
    switch (key) {
      case 'subscriberID':
        value = this.subscriberID$.getValue();
        break;
      case 'locationID':
        value = this.locationID$.getValue();
        break;
      default:
        break;
    }
    return value;
  }
}
