import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '@app/services/auth.service';
import { environment } from '@env/environment';

@Component({
  selector: 'akpc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private titleService: Title,
    private authService: AuthService) {
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit() {
    console.log(`[AppComponent::ngOnInit]`);
    let title = 'Astute Point';
    if (!environment.production) {
      title = `${title} (${environment.name})`;
    }
    this.setTitle(title);
    this.authService.isAuthenticated();
  }


}
