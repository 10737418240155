import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../../product/models/product.model';

@Pipe({
  name: 'productName'
})
export class ProductNamePipe implements PipeTransform {

  transform(productID: number, productList: Product[]): any {
    const product = productList.find(productIterate => productIterate.productID === productID);
    console.log('ProductNamePipe', productID, productList, 'found', product);
    return product.name || '-';
  }

}
